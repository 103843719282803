import { EServiceTypeNumber } from '@aca-new/app/shared/models/enums/service-type-number.enum';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';

// for Product Inspections
export const PRODUCT_INSPECTIONS_SERVICE_MAP: Record<string, EServiceType> = {
  [EServiceTypeNumber.PSI]: EServiceType.PSI,
  [EServiceTypeNumber.CLC]: EServiceType.CLC,
  [EServiceTypeNumber.DUPRO]: EServiceType.DUPRO,
  [EServiceTypeNumber.IPC]: EServiceType.IPC,
  [EServiceTypeNumber.PEO]: EServiceType.PEO,
  [EServiceTypeNumber.PM]: EServiceType.PM,
  [EServiceTypeNumber.SR]: EServiceType.SR,
  [EServiceTypeNumber.SC]: EServiceType.SC,
};

// for Testing
export const TESTING_SERVICE_SERVICE_MAP: Record<string, EServiceType> = {};

// for Certifications and VOC
export const CERTIFICATIONS_AND_VOC_SERVICE_SERVICE_MAP: Record<string, EServiceType> = {
  [EServiceTypeNumber.SASO]: EServiceType.SASO,
};

// for Supplier Audits
export const SUPPLIER_AUDITS_SERVICE_SERVICE_MAP: Record<string, EServiceType> = {};

// for all services map
export const SERVICE_TYPE_MAP: Record<string, EServiceType> = {
  ...PRODUCT_INSPECTIONS_SERVICE_MAP,
  ...CERTIFICATIONS_AND_VOC_SERVICE_SERVICE_MAP,
};

// for Product Inspections support services
export const SUPPORT_PRODUCT_INSPECTIONS_SERVICE_TYPES: string[] = [
  EServiceType.PSI,
  EServiceType.DUPRO,
  EServiceType.IPC,
  EServiceType.CLC,
  EServiceType.PEO,
  EServiceType.PM,
  EServiceType.SR,
  EServiceType.SC,
  EServiceType.LT,
  EServiceType.SABER,
  EServiceType.SASO,
  EServiceType.GMARK,
  EServiceType.MA,
  EServiceType.EA,
  EServiceType.ENVA,
  EServiceType.STRA,
  EServiceType.CTPAT,
  EServiceType.DR,
  EServiceType.GMP,
  EServiceType.GHP,
];

// for all support services
export const SUPPORT_SERVICE_TYPES: string[] = [...SUPPORT_PRODUCT_INSPECTIONS_SERVICE_TYPES];
