<div class="data-table">
  <div
    *ngIf="!isSimpleTable && isToolbarTopVisible"
    class="data-table__toolbar-top"
  >
    <div class="data-table__toolbar-top__actions">
      <app-super-master-selector
        *ngIf="isSuperMasterSelectorVisible && isSuperMaster"
        class="qima-mr-xs"
        (superMasterSelectorClick)="onSuperMasterSelectorClick($event)"
      ></app-super-master-selector>
      <ng-content select=".table-toolbar-top-actions"></ng-content>
    </div>
    <div class="data-table__toolbar-top__search">
      <ng-content select=".table-toolbar-top-search"></ng-content>
    </div>
  </div>
  <app-table
    (tableSort)="onTableSort($event)"
    (tableRowClick)="onTableRowClick($event)"
    (tableCheckboxClick)="onTableCheckboxClick($event)"
    (tableHeadCheckboxClick)="onTableHeadCheckboxClick($event)"
    (dataTableIconCellClick)="onTableIconClick($event)"
    [tableIsToggleCheckboxOnRowClick]="isSelectRowOnRowClick"
    [tableIsHeadVisible]="isHeadVisible"
    [tableHeaders]="tableHeaders"
    [layoutStyle]="layoutStyle"
    [tableTop]="top"
    [tableBottom]="bottom"
    [tableData]="tableViewData"
    [tableEmptyMessage]="emptyMessageState"
    [tableHasSelectedBackgroundColor]="hasSelectedBackgroundColor"
    [tableIsStriped]="isStriped"
    [tableIsHover]="isHover"
    [tableIsHeaderFix]="isHeaderFix"
    [tableActiveRowIndex]="activeRowIndex"
    [tableBeforeRowClick]="beforeRowClick"
    [tableBeforeCheckboxClick]="beforeCheckboxClick"
    [tableCanClick]="canClick"
  ></app-table>
  <div
    *ngIf="!isSimpleTable && isToolbarBottomVisible"
    class="data-table__toolbar-bottom"
    [ngClass]="{ 'data-table__toolbar-bottom--is-absolute': isBottomAbsolute }"
  >
    <ng-content select=".table-toolbar-bottom-content"></ng-content>
    <qima-pagination
      *ngIf="isPaginationVisible"
      [qimaPaginationEntriesAmount]="tableTotalSize"
      [qimaPaginationEntriesPerPageList]="pageSizes"
      [qimaPaginationActivePage]="activePage"
      (qimaPaginationActivePageChange)="tablePaginationActivePageChange($event)"
      (qimaPaginationEntriesPerPageChange)="tablePaginationPageSizeChange($event)"
    ></qima-pagination>
  </div>
</div>
