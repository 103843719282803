import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { Injectable } from '@angular/core';

/**
 * Service to handle conversion between service type codes and display texts.
 * These bidirectional conversions (SP <-> SC) were added to fix bug SP-21815,
 * where internal codes need to be mapped to different display values and vice versa.
 */
@Injectable()
export class ServiceTypeService {
  /**
   * @description
   * Converts a service type code used internally to a service type text used for display purposes.
   * @param {string} serviceTypeCode - The service type code used internally.
   * @returns {string} - The service type text used for display purposes.
   */
  public getServiceTypeTextFromCode(serviceTypeCode: string): string {
    switch (serviceTypeCode.toUpperCase()) {
      case EServiceType.SP:
        return EServiceType.SC;
      default:
        return serviceTypeCode;
    }
  }

  /**
   * @description
   * Converts a service type text used for display purposes to a service type code used internally.
   * @param {string} serviceTypeText - The service type text used for display purposes.
   * @returns {string} - The service type code used internally.
   */
  public getServiceTypeCodeFromText(serviceTypeText: string): string {
    switch (serviceTypeText.toUpperCase()) {
      case EServiceType.SC:
        return EServiceType.SP;
      case EServiceType.WCP:
        return EServiceType.CSM;
      default:
        return serviceTypeText;
    }
  }
}
