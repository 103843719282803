import { IMultipleLineTableCell } from '@aca-new/app/pages/bookings/shared/models/interfaces/multiple-line-table-cell.interface';
import { ICustomizedTableCellComponent } from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/interfaces/customized-table-cell.interface';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QimaAutoTooltipDirectiveModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-multiple-line-table-cell',
  templateUrl: './multiple-line-table-cell.component.html',
  styleUrls: ['./multiple-line-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, QimaAutoTooltipDirectiveModule, QimaTooltipDirectiveModule],
})
export class MultipleLineTableCellComponent implements ICustomizedTableCellComponent {
  public data: IMultipleLineTableCell = {};
}
