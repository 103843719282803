import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaProgressIndicatorModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-progress-indicator-with-arrow',
  templateUrl: './progress-indicator-with-arrow.component.html',
  styleUrls: ['./progress-indicator-with-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [QimaProgressIndicatorModule, QimaTooltipDirectiveModule, TranslateModule, CommonModule],
})
export class ProgressIndicatorWithArrowComponent {
  /**
   * @description
   * The value for ProgressIndicator
   * @type {number}
   * @default 0
   */
  @Input('progressIndicatorWithArrowValue')
  public value: number = 0;

  /**
   * @description
   * The amount for QIMA Progress Indicator
   * @type {number}
   * @default 0
   */
  @Input('progressIndicatorWithArrowAmount')
  public amount: number = 4;

  /**
   * @description
   * The tooltip for ProgressIndicator
   * @type {string}
   * @default ''
   */
  @Input('progressIndicatorWithArrowTooltip')
  public tooltip: string = '';

  public constructor(public readonly qimaTooltip: QimaTooltipService) {}
}
