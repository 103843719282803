import { MultipleLineTableCellComponent } from '@aca-new/app/pages/bookings/shared/components/multiple-line-table-cell/multiple-line-table-cell.component';
import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableHeaderLayoutMode } from '@aca-new/app/shared/components/table/shared/enums/table-header-layout-mode.enum';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { EQimaTableHeadCellSort } from '@qima/ngx-qima';

const CHECKBOX: ITableHeader = {
  key: 'checkbox',
  label: '',
  type: ETableDataType.CHECKBOX,
  mode: ETableHeaderLayoutMode.STICKY_LEFT,
  width: '36',
};
const SERVICE_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'serviceType',
  iceCubeKey: 'serviceTypeText',
  label: 'TABLE.TYPE',
  type: ETableDataType.ICE_CUBE,
  width: '70',
};
const BOOKING_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'bookingDate',
  label: 'TABLE.BOOKING_DATE',
  type: ETableDataType.STRING,
  width: '120',
};
const SERVICE_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'inspectionDateMMMFormat',
  sortKey: 'inspectionDate',
  label: 'TABLE.SERVICE_DATE',
  type: ETableDataType.STRING,
  width: '124',
};
const RE_INSPECTION: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'isReInspection',
  label: 'TABLE.RE_INSPECTION',
  type: ETableDataType.STRING,
  width: '124',
};
const PRODUCT_NAME: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'productName',
  label: 'TABLE.PRODUCT_NAME',
  type: ETableDataType.STRING,
  width: '156',
};
const PRODUCT_NAME_MULTIPLE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'productName',
  label: 'TABLE.PRODUCT_NAME',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '170',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.value = 'TABLE.PRODUCTS';
  },
};
const PRODUCT_REF: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'prodReference',
  label: 'TABLE.PRODUCT_REF',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '168',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.label = cellData.label?.replace(/,/g, '###');
    cellData.value = 'TABLE.SKU';
  },
};
const PO_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'poNumber',
  label: 'TABLE.PO_NO',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '132',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.label = cellData.label?.replace(/\//g, '###');
    cellData.value = 'TABLE.PO_NOS';
  },
};
const BOOKING_REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'clientRefNb',
  label: 'TABLE.BOOKING_REF_NO',
  type: ETableDataType.STRING,
  width: '150',
};
const CLIENT_REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'clientRefNumber',
  label: 'TABLE.CLIENT_REF_NUMBER',
  type: ETableDataType.STRING,
  width: '150',
};
const REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'orderNumber',
  label: 'TABLE.REF_NO',
  type: ETableDataType.STRING,
  width: '160',
};
const SUPPLIER: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'supplierName',
  label: 'TABLE.SUPPLIER',
  type: ETableDataType.STRING,
  width: '130',
};
const RESULT: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'overrallResult',
  label: 'TABLE.RESULT',
  type: ETableDataType.BADGE,
  mode: ETableHeaderLayoutMode.STICKY_RIGHT,
  width: '94',
};
const YOUR_DECISION: ITableHeader = {
  key: 'status',
  label: 'TABLE.YOUR_DECISION',
  type: ETableDataType.DOT_LABEL,
  mode: ETableHeaderLayoutMode.STICKY_RIGHT,
  tooltip: 'REPORTS.YOUR_DECISION_TOOLTIP',
  width: '100',
};
const COUNTRY: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'countryName',
  label: 'Country',
  type: ETableDataType.STRING,
  width: '100',
};
const AUDIT_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditDate',
  sortKey: 'auditDate',
  label: 'TABLE.AUDIT_DATE',
  type: ETableDataType.STRING,
};
const AUDIT_SCHEME: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditScheme',
  label: 'TABLE.AUDIT_SCHEME',
  type: ETableDataType.STRING,
};
const CERT_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'certificateType',
  label: 'TABLE.CERT_TYPE',
  type: ETableDataType.STRING,
};
const PRODUCER_ENTITY: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'producerLegalEntity',
  label: 'TABLE.PRODUCER_ENTITY',
  type: ETableDataType.STRING,
};
const WQS_ID: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'wqsId',
  label: 'TABLE.WQS_ID',
  type: ETableDataType.STRING,
};
const ORDER_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'orderNumber',
  label: 'TABLE.ORDER_NO',
  type: ETableDataType.STRING,
};
const WQS_RESULT: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'result',
  label: 'TABLE.RESULT',
  type: ETableDataType.STRING,
};
const CERT_EXP_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'certificateExpirationDate',
  label: 'TABLE.CERT_EXP_DATE',
  type: ETableDataType.STRING,
};
const AUDIT_WINDOW_DATES: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditWindow',
  label: 'TABLE.AUDIT_WINDOW_DATES',
  type: ETableDataType.MULTIPLE_LABEL_CELL,

  cellTransform(cellData: ITableBodyCell, pageItem): void {
    const fromDate = `${pageItem?.auditWindowFromDate}`;
    const toDate = `${pageItem?.auditWindowToDate}`;

    if (fromDate && toDate) {
      cellData.label = `${fromDate} to ${toDate}`;
    }
  },
};
const REPORT_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'reportDueDate',
  label: 'TABLE.REPORT_DATE',
  type: ETableDataType.STRING,
  width: '110',
};
const TEST_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'testStartDate',
  label: 'TABLE.TEST_DATE',
  type: ETableDataType.STRING,
  width: '100',
};
const OFFICE_LOCATION: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'office',
  label: 'TABLE.LOCATION',
  type: ETableDataType.STRING,
  width: '190',
};
const TESTING_PROGRAM: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'program',
  label: 'TABLE.TESTING_PROGRAM',
  type: ETableDataType.STRING,
  width: '130',
};
const REPORT_NUMBER: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'reportNo',
  label: 'TABLE.REPORT_NO',
  type: ETableDataType.STRING,
  width: '130',
};
const BOOKING_STATUS: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'status',
  label: 'TABLE.STATUS',
  type: ETableDataType.PROGRESS_INDICATOR,
  width: '115',
};
const BOOKING_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'bookingType',
  label: 'TABLE.BOOKING_TYPE',
  type: ETableDataType.STRING,
  width: '115',
};
const SERVICE_CATEGORY: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'subInfo',
  isFrontSort: true,
  label: 'TABLE.QIMA_CB_OFFICE',
  type: ETableDataType.STRING,
  width: '124',
};
const SABER_ORDER: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'clientRefNo',
  isFrontSort: true,
  label: 'TABLE.SABER_ORDER',
  type: ETableDataType.STRING,
  width: '226',
};
const PROJECT_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'masterReportNo',
  label: 'TABLE.PROJECT_NO',
  type: ETableDataType.STRING,
  width: '130',
};
const SUB_REPORT_DESCRIPTION: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'subReportDescription',
  label: 'TABLE.SUB_REPORT_DESCRIPTION',
  type: ETableDataType.STRING,
  width: '130',
};
const SCHEDULED_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: `auditDateFrom`,
  label: 'TABLE.SCHEDULED_DATE',
  width: '140',
  type: ETableDataType.CUSTOMIZED,
  cellTransform(cellData: ITableBodyCell, pageItem?: Record<string, PageItemType>): void {
    // ACA: src/client/app/common/ajax/common-ajax.service.js:2098
    const statusNumber = parseInt(pageItem?.status as string, 10);
    const lines = statusNumber < 150 ? [] : [`${pageItem?.auditDateFrom} -`, pageItem?.auditDateTo];

    cellData.customizedCellConfiguration = {
      component: MultipleLineTableCellComponent,
      data: { lines },
    };
  },
};
const AUDIT_CYCLE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditCycle',
  label: 'TABLE.AUDIT_CYCLE',
  type: ETableDataType.STRING,
  width: '128',
};
const CERTIFICATION_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'certificateTypes',
  label: 'TABLE.CERTIFICATION_TYPE',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '140',
  cellTransform(cellData: ITableBodyCell, pageItem?: Record<string, PageItemType>): void {
    const certTypes = (pageItem?.certificateTypes ?? []) as Array<{ certTypeText: string }>;

    cellData.label = certTypes
      .map((it): string => it.certTypeText)
      .reverse()
      .join('###');
    cellData.value = 'TABLE.CERTIFICATION_TYPES';
  },
};
const PREFERRED_AUDIT_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'serviceDate',
  label: 'TABLE.PREFERRED_AUDIT_DATE',
  type: ETableDataType.STRING,
  width: '128',
};
const ORDER_STATUS: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'status',
  label: 'TABLE.ORDER_STATUS',
  width: '115',
  type: ETableDataType.PROGRESS_INDICATOR,
};

export {
  CHECKBOX,
  SERVICE_TYPE,
  BOOKING_DATE,
  BOOKING_TYPE,
  SERVICE_DATE,
  SERVICE_CATEGORY,
  SABER_ORDER,
  REF_NO,
  YOUR_DECISION,
  RESULT,
  SUPPLIER,
  BOOKING_REF_NO,
  PO_NO,
  PRODUCT_REF,
  PRODUCT_NAME,
  RE_INSPECTION,
  COUNTRY,
  PRODUCT_NAME_MULTIPLE,
  CLIENT_REF_NO,
  AUDIT_DATE,
  AUDIT_SCHEME,
  CERT_TYPE,
  PRODUCER_ENTITY,
  WQS_ID,
  ORDER_NO,
  WQS_RESULT,
  CERT_EXP_DATE,
  AUDIT_WINDOW_DATES,
  REPORT_DATE,
  TEST_DATE,
  OFFICE_LOCATION,
  TESTING_PROGRAM,
  REPORT_NUMBER,
  BOOKING_STATUS,
  PROJECT_NO,
  SUB_REPORT_DESCRIPTION,
  SCHEDULED_DATE,
  AUDIT_CYCLE,
  CERTIFICATION_TYPE,
  PREFERRED_AUDIT_DATE,
  ORDER_STATUS,
};
