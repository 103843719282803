import { IDraft } from '@aca-new/app/pages/book/shared/models/interfaces/draft.interface';
import { IPreviousOrders } from '@aca-new/app/pages/bookings/shared/models/interfaces/bookings.interface';
import { IDynamicBooking, IDynamicBookingMenus } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/dynamic-booking-menus.interface';
import { AsideHttpService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.http.service';
import { SuperMasterSelectorService } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/shared/service/super-master-selector.service';
import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { ITableResponse } from '@aca-new/app/shared/models/interfaces/table-response.interface';
import { IUrl } from '@aca-new/app/shared/models/interfaces/url.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  public readonly refreshDraftTable$: Subject<void> = new Subject<void>();

  private _cachedDynamicSolutions$?: Observable<Array<ISelect>>;

  public constructor(
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService,
    private readonly _superMasterSelectorService: SuperMasterSelectorService,
    private readonly _asideHttpService: AsideHttpService
  ) {}

  public updateRefreshDraftTable(): void {
    this.refreshDraftTable$.next();
  }

  public getPreviousOrders$(url: string): Observable<IPreviousOrders[]> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<ITableResponse<IPreviousOrders[]>>>(url.replace('{userId}', this._storageService.getItem(EStorageKeys.USER_ID) as string), {
        observe: 'response',
      })
      .pipe(
        map((response: Readonly<HttpResponse<IHttpResponseBody<ITableResponse<IPreviousOrders[]>>>>): IPreviousOrders[] => {
          return response?.body?.content?.pageItems || [];
        })
      );
  }

  public createDraftFromPreviousOrder$(orderId: string, serviceType: string = 'psi'): Observable<QimaNullableType<IDraft>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<QimaNullableType<IDraft>>(`${SERVER_URL}/user/${userId}/draft/previous-psi-order/${orderId}?service-type=${serviceType}`, {
        observe: 'body',
      })
      .pipe(map((response: QimaNullableType<IDraft>): QimaNullableType<IDraft> => response));
  }

  public getParamsUrl(urlObj: IUrl, params: IDataTableQueryParams, superMasterUserId: string): string {
    const searchParams = new URLSearchParams(urlObj.params);

    searchParams.set('keyword', params.keyword.trim());
    searchParams.set('page', params.page.toString());
    searchParams.set('page-size', params.pageSize.toString());
    searchParams.set('sortType', params.sortType ?? '');
    searchParams.set('sortField', params.sortField ?? '');

    return this._superMasterSelectorService.transformUrl(urlObj.url + searchParams.toString(), superMasterUserId);
  }

  public getDynamicBusinessSolutions$(): Observable<Array<ISelect>> {
    if (!this._cachedDynamicSolutions$) {
      this._cachedDynamicSolutions$ = this._asideHttpService.getDynamicBookingMenus$().pipe(
        map((data: IDynamicBookingMenus): Array<ISelect> => {
          const solutions: ISelect[] = [];

          Object.values(data)
            .flat()
            .forEach((item: IDynamicBooking): void => {
              if (item.serviceCategory) {
                const value: string = item.serviceCategory.toLowerCase();
                const label: string = value === EBusinessSolution.SELF_ASSESSMENT ? 'COMMON.TRACEABILITY' : item.serviceCategory;

                solutions.push({ label, value });
              }
            });

          return solutions;
        }),
        shareReplay({ bufferSize: 1, refCount: false })
      );
    }

    return this._cachedDynamicSolutions$;
  }
}
