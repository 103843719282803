<div class="multiple-line-table-cell">
  @for (line of data.lines; track $index) {
    <div
      class="multiple-line-table-cell__line"
      qimaAutoTooltip
      [qimaAutoTooltipText]="line"
      [qimaAutoTooltipHasArrow]="true"
    >
      {{ line }}
    </div>
  } @empty {
    <div class="multiple-line-table-cell__line">---</div>
  }
</div>
