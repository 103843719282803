import { AppUserSettingService } from '../../services/user-services/app-user-setting/app-user-setting.service';
import { DEFAULT_BUSINESS_SOLUTIONS } from '@aca-new/app/pages/home/shared/constants/default-business-solutions.constants';
import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EQimaInputSelectStyle } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-business-solution-select',
  styleUrls: ['./business-solution-select.component.scss'],
  templateUrl: './business-solution-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessSolutionSelectComponent implements OnInit {
  /**
   * @description
   * Default selected business solution
   * @type {string}
   * @default EBusinessSolution.INSPECTIONS
   */
  @Input('businessSolutionSelected')
  public selected: string = this._storageService.getItem(EStorageKeys.SELECTED_BUSINESS_SOLUTION) || EBusinessSolution.INSPECTIONS;

  /**
   * @description
   * The business solution options
   * @type {ISelect[]}
   * @default []
   */
  @Input('businessSolutions')
  public businessSolutions: ISelect[] = [];

  /**
   * @description
   * max height of the dropdown menu
   * @type {number}
   * @default 400
   */
  @Input('businessSolutionMenuMaxHeight')
  public businessSolutionMenuMaxHeight: number = 400;

  @Output('businessSolutionChange')
  public readonly businessSolutionChange: EventEmitter<Readonly<string>> = new EventEmitter<Readonly<string>>();

  public selectedStyle: EQimaInputSelectStyle = EQimaInputSelectStyle.PRIMARY;

  public constructor(
    private readonly _storageService: StorageService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appUserSettingService: AppUserSettingService
  ) {}

  public ngOnInit(): void {
    if (this._appUserSettingService.getEnableFoodCertification()) {
      this.businessSolutions = this.businessSolutions.filter((item): boolean => item.value === EBusinessSolution.FOOD_CERTIFICATION || item.value === EBusinessSolution.AUDITS);

      if (!this.businessSolutions.map((it): string => it.value).includes(this.selected)) {
        this.selected = EBusinessSolution.AUDITS;
      }
    }

    // TODO: The business solutions should be retrieved from parent component, remove below codes once it's done
    if (isEmpty(this.businessSolutions)) {
      this.businessSolutions = DEFAULT_BUSINESS_SOLUTIONS;
    }
  }

  public onBusinessSolutionChange(selected: Readonly<string>): void {
    this._storageService.setItem(EStorageKeys.SELECTED_BUSINESS_SOLUTION, selected);
    this.businessSolutionChange.emit(selected);
  }

  public updateView(): void {
    this._changeDetectorRef.markForCheck();
  }
}
